<template>
  <b-modal id="updateDeadlineModal" centered hide-footer header-bg-variant="white">
    <div class="d-flex flex-column justify-content-center align-items-start px-1">
      <h3 class="mb-1">Novo prazo de vencimento</h3>
      <span class="mb-2">
        Informe abaixo uma nova data de vencimento para o título de número
        {{ selectedItem.numero }}.
      </span>
      <validation-observer ref="resetForm" #default="{ invalid }" class="w-100">
        <b-row>
          <b-col cols="6">
            <b-form-group label="Data de vencimento atual" label-for="tipo">
              <flat-pickr
                v-model="data_vencimento"
                class="assignor-selector form-control bg-white"
                :config="config"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Nova data de vencimento" label-for="tipo">
              <validation-provider
                #default="{ errors }"
                name="Nova data de vencimento"
                vid="tipo"
                rules="required"
              >
                <flat-pickr
                  v-model="nova_data_vencimento"
                  class="assignor-selector form-control bg-white"
                  :config="config"
                  placeholder="Informe uma data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex flex-row justify-content-around mt-2">
          <b-button
            variant="outline-secondary"
            class="mb-1 mr-1 text-dark font-weight-bolder"
            @click="$bvModal.hide('updateDeadlineModal')"
          >
            Cancelar
          </b-button>
          <b-button
            variant="secondary"
            class="mb-1 bg-light-secondary font-weight-bolder"
            :disabled="invalid"
            @click="updateDeadlineConfirm"
          >
            Aplicar data de vencimento
          </b-button>
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BFormGroup, BForm, BRow, BCol } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

export default {
  name: 'DecreaseValueModal',
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    flatPickr,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nova_data_vencimento: '',
      data_vencimento: '',
      config: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
    }
  },
  methods: {
    async updateDeadlineConfirm() {
      this.$swal({
        title: 'Confirmar alteração?',
        text: 'O prazo de vencimento será alterado.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Alterar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.updateDeadline()
        }
      })
    },
    async updateDeadline() {
      try {
        const payload = {
          id: this.selectedItem.id,
          data_referencia: this.selectedItem.data_vencimento,
          nova_data_vencimento: this.nova_data_vencimento,
        }
        await this.$store.dispatch('admin/updateDeadline', payload)
        this.$swal({
          title: 'Prazo de vencimento alterado!',
          text: 'O prazo de vencimento foi alterado com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        this.nova_data_vencimento = ''
        this.$parent.$refs.titulosDatatable.getData()
        this.$bvModal.hide('updateDeadlineModal')
      } catch (error) {}
    },
  },
  watch: {
    selectedItem() {
      if (this.selectedItem.data_vencimento) {
        this.data_vencimento = this.selectedItem.data_vencimento.split('-').reverse().join('/')
      }
    },
  },
}
</script>
