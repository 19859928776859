<template>
  <b-modal id="payModal" centered hide-footer header-bg-variant="white">
    <div class="d-flex flex-column justify-content-center align-items-start px-1">
      <h3 class="mb-1">Marcar título de número {{ selectedItem.numero }} como pago</h3>
      <validation-observer ref="resetForm" #default="{ invalid }" class="w-100">
        <b-row>
          <b-col cols="6">
            <b-form-group label="Data de vencimento" label-for="tipo">
              <flat-pickr
                v-model="data_vencimento"
                class="assignor-selector form-control bg-white"
                :config="config"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Data do pagamento" label-for="tipo">
              <validation-provider
                #default="{ errors }"
                name="Data do pagamento"
                vid="tipo"
                rules="required"
              >
                <flat-pickr
                  v-model="data_pagamento"
                  class="assignor-selector form-control bg-white"
                  :config="config"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Valor do título" label-for="valor">
              <money
                v-model="selectedItem.valor_face"
                class="form-control"
                v-bind="money"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Valor pago" label-for="valorPago">
              <validation-provider #default="{ errors }" name="Valor pago" rules="required">
                <money
                  v-model="valor_pago"
                  class="form-control"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  v-bind="money"
                  :maxlength="13"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex flex-row justify-content-around mt-2">
          <b-button
            variant="outline-secondary"
            class="mb-1 mr-1 text-dark font-weight-bolder"
            @click="$bvModal.hide('payModal')"
          >
            Cancelar
          </b-button>
          <b-button
            variant="secondary"
            class="mb-1 bg-light-secondary font-weight-bolder"
            :disabled="invalid"
            @click="payConfirm"
          >
            Marcar como pago
          </b-button>
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BFormGroup, BForm, BRow, BCol } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'CancelModal',
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    BFormGroup,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_vencimento: '',
      data_pagamento: '',
      valor_pago: '',
      config: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      money: {
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
    }
  },
  methods: {
    async payConfirm() {
      this.$swal({
        title: 'Confirmar pagamento?',
        text: 'O título será marcado como pago.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Marcar como pago',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.pay()
        }
      })
    },
    async pay() {
      try {
        const payload = {
          id: this.selectedItem.id,
          data_pagamento: this.data_pagamento,
          valor_pago: this.valor_pago,
        }
        await this.$store.dispatch('admin/pay', payload)
        this.$swal({
          title: 'Título marcado como pago!',
          text: 'O título foi marcado como pago com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        this.data_pagamento = ''
        this.valor_pago = ''
        this.$parent.$refs.titulosDatatable.getData()
        this.$bvModal.hide('payModal')
      } catch (error) {}
    },
  },
  watch: {
    selectedItem() {
      if (this.selectedItem.data_vencimento) {
        this.data_vencimento = this.selectedItem.data_vencimento.split('-').reverse().join('/')
      }
    },
  },
}
</script>
