<template>
  <b-modal id="decreaseValueModal" centered hide-footer header-bg-variant="white">
    <div class="d-flex flex-column justify-content-center align-items-start px-1">
      <h3 class="mb-1">Redução de valor</h3>
      <span class="mb-2">
        Informe abaixo o novo valor do título de número {{ selectedItem.numero }}.
      </span>
      <validation-observer ref="resetForm" #default="{ invalid }" class="w-100">
        <b-row>
          <b-col cols="6">
            <b-form-group label="Data de redução" label-for="tipo">
              <validation-provider #default="{ errors }" name="Data de redução" rules="required">
                <flat-pickr
                  v-model="reference_date"
                  class="assignor-selector form-control bg-white"
                  :config="config"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6"></b-col>
          <b-col cols="6">
            <b-form-group label="Valor atual do título" label-for="valor">
              <money
                v-model="selectedItem.valor_face"
                class="form-control"
                v-bind="money"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Informe o novo valor" label-for="valorPago">
              <validation-provider
                #default="{ errors }"
                name="Informe o novo valor"
                rules="required"
              >
                <money
                  v-model="novo_valor"
                  class="form-control"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  v-bind="money"
                  :maxlength="13"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex flex-row justify-content-around mt-2">
          <b-button
            variant="outline-secondary"
            class="mb-1 mr-1 text-dark font-weight-bolder"
            @click="$bvModal.hide('decreaseValueModal')"
          >
            Cancelar
          </b-button>
          <b-button
            variant="secondary"
            class="mb-1 bg-light-secondary font-weight-bolder"
            :disabled="invalid"
            @click="decreaseValueConfirm"
          >
            Aplicar novo valor
          </b-button>
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BFormGroup, BForm, BRow, BCol } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'

export default {
  name: 'DecreaseValueModal',
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    flatPickr,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reference_date: new Date(),
      config: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      novo_valor: '',
      money: {
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
    }
  },
  methods: {
    async decreaseValueConfirm() {
      this.$swal({
        title: 'Confirmar redução de valor?',
        text: 'O valor do título será reduzido.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Reduzir valor',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.decreaseValue()
        }
      })
    },
    async decreaseValue() {
      try {
        const payload = {
          id: this.selectedItem.id,
          novo_valor: this.novo_valor,
          data_referencia: this.reference_date,
        }
        await this.$store.dispatch('admin/decreaseValue', payload)
        this.$swal({
          title: 'Valor reduzido!',
          text: 'O valor do título foi reduzido com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        this.novo_valor = ''
        this.$parent.$refs.titulosDatatable.getData()
        this.$bvModal.hide('decreaseValueModal')
      } catch (error) {}
    },
  },
}
</script>
